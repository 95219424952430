import React from 'react'
import { graphql } from 'gatsby'

import GDPR from '../components/Pages/GDPR'

const GDPRPage = ({ data, location }) => {
  const localeID = 'sk_SK'

  return <GDPR location={location} localeID={localeID} data={data} />
}

export default GDPRPage

export const pageQuery = graphql`
  query GDPRQuerySK {
    wpPage(slug: { eq: "gdpr" }, locale: { id: { eq: "sk_SK" } }) {
      title
      content
    }
  }
`
