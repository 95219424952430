import React from 'react'
import { Link } from 'gatsby'
import parse from 'html-react-parser'

import Layout from '../Layout'

import { Content, BreadCrumbs } from '../Atoms'
import { labels, localeUrls } from '../../langs/langs'

const GDPR = ({ localeID, location, data }) => {
  const page = data.wpPage
  return (
    <Layout location={location} title={labels[localeID]['CZONE']} localeID={localeID}>
      <BreadCrumbs>
        <Link to={localeUrls[localeID]['HOME']}>{labels[localeID]['HOME']}</Link>
        <span>/</span>
        <span className="text-dark-gray">{page.title}</span>
      </BreadCrumbs>
      <div className="py-24 sm:py-32">
        <Content className="max-w-3xl">
          <h1 className="text-5xl -tracking-[0.5px] font-bold pb-8">{page.title}</h1>
          <article>{parse(!!page.content ? page.content : '')}</article>
        </Content>
      </div>
    </Layout>
  )
}

export default GDPR
